// LoginRegSection.js

import React from 'react';
import { Link } from 'react-router-dom';
import './App.css'; // Import your custom CSS file for styling

const LoginReg = () => {
  return (
    <section className="loginReg">
      <div className="container">
        <div className="loginReg-row">
          <div className="single-loginReg">
            <div className="inner">
              <div className="single-content">
                <span>Already have an account?</span>
                <h4>Login</h4>
                <Link to="/Login">Go to Login</Link>
              </div>
            </div>
          </div>

          {/* Second single-loginReg component */}
          <div className="single-loginReg">
            <div className="inner">
              <div className="single-content">
                <span>New User?</span>
                <h4>Register</h4>
                <Link to="/RegisterPage">Go to Register</Link>
              </div>
            </div>
          </div>

          {/* Third single-loginReg component */}
          <div className="single-loginReg">
            <div className="inner">
              <div className="single-content">
                <span>Sign Out</span>
                <h4>Follow the Steps</h4>
                <Link to="/SignOut">Learn More</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default LoginReg;