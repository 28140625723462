// s3uploader.js
import AWS from 'aws-sdk/global';
import S3 from 'aws-sdk/clients/s3';

AWS.config.update({
  region: process.env.REACT_APP_AWS_PROJECT_REGION,
  accessKeyId:process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey:process.env.REACT_APP_AWS_SECRET_ACCESS_KEY ,
});

const myBucket = new S3({
  params: { Bucket:process.env.REACT_APP_AWS_S3_BUCKET },
  region:process.env.REACT_APP_AWS_PROJECT_REGION ,
});

export const uploadFile = (file, username) => {
  const params = {
    Body: file,
    Bucket:process.env.REACT_APP_AWS_S3_BUCKET,
    Key: `input/${username}_${file.name}`, // Uploads file to the input folder with username prefix
  };
  
  return new Promise((resolve, reject) => {
    myBucket.putObject(params, (err, data) => {
      if (err) {
        reject(err);
      } else {
        resolve(data);
      }
    });
  });
};

export const listProcessedFiles = (username) => {
  const params = {
    Bucket:process.env.REACT_APP_AWS_S3_BUCKET,
    Prefix: `anonymized_output/anonymized_cleaned_${username}_`, // List files in the output folder with username prefix
  };

  return new Promise((resolve, reject) => {
    myBucket.listObjectsV2(params, (err, data) => {
      if (err) {
        reject(err);
      } else {
        resolve(data.Contents);
      }
    });
  });
};

export const getFileDownloadUrl = (key) => {
  const params = {
    Bucket:process.env.REACT_APP_AWS_S3_BUCKET,
    Key: key,
    Expires: 60, // URL expiration time in seconds
  };

  return myBucket.getSignedUrl('getObject', params);
};
