import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import './App.css'; // Use the provided CSS for styling

const SignOut = () => {
  const { signout } = useAuth(); // Get the signout function from AuthContext
  const navigate = useNavigate();

  const handleSignOut = async () => {
    try {
      await signout(); // Call the signout function from AuthContext
      alert('Successfully signed out');
      navigate('/'); // Redirect to the home page after sign-out
    } catch (error) {
      console.error('Error signing out user:', error);
      alert('Error signing out user. Please try again.');
    }
  };

  return (
    <div className="signout">
      <div className="overlay"></div>
      <div className="inner">
        <div className="signout-left"></div>
        <div className="signout-form">
          <h2>Sign Out</h2>
          <p>Are you sure you want to sign out?</p>
          <form className="form">
            <div className="form-group signout-btn">
              <button type="button" className="btn" onClick={handleSignOut}>
                Sign Out
              </button>
            </div>
            <p>
              <a href="/">Cancel</a>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignOut;
