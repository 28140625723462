// LoginPage.jsx
import React, { useState } from 'react';
import { useAuth } from './AuthContext'; // Import the AuthContext
import { signIn } from 'aws-amplify/auth'; // Import Auth from AWS Amplify
import './App.css'; // Use the provided CSS
import { useNavigate, Link } from 'react-router-dom'; // Import useNavigate for redirection

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { signin } = useAuth(); // Get the signIn method from the context
  const navigate = useNavigate(); // Hook for programmatic navigation

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!email || !password) {
      setError('Email and password are required.');
      console.error('Email or password is missing');
      return;
    }
    try {
      // Sign in with AWS Amplify
      const user = await signIn({username:email, password:password});
      console.log('User logged in:', user);
      
      // Update the context with the user sign-in status
      signin();

      // Redirect to the upload page
      navigate('/Upload');
    } catch (error) {
      console.error('Error signing in:', error);
      setError('Failed to login. Please check your email and password.');
    }
  };

  return (
    <div className="login">
      <div className="overlay"></div>
      <div className="inner">
        <div className="login-left"></div>
        <div className="login-form">
          <h2>Login</h2>
          <p>Welcome back! Please login to your account.</p>
          <form className="form" onSubmit={handleLogin}>
            <div className="form-group">
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                style={{ textTransform: 'none' }} // Ensure text is not capitalized
              />
            </div>
            <div className="form-group">
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div className="form-group login-btn">
              <button type="submit" className="btn">
                Login
              </button>
            </div>
            <div className="form-group checkbox">
              <label>
                <input type="checkbox" />
                Remember me
              </label>
            </div>
            <div className="links">
              <Link className="link-item" to="/RegisterPage">
                Don't have an account? Register
              </Link>
              <a className="link-item lost-pass" href="/ForgotPassword">
                Forgot your password?
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};


export default LoginPage;
