// AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';

import { getCurrentUser,signOut } from 'aws-amplify/auth';
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isSignedIn, setIsSignedIn] = useState(() => {
    const saved = sessionStorage.getItem("isSignedIn");
    return saved === "true"; // Convert string to boolean
  });

  const signin = () => setIsSignedIn(true);
  const [loading, setLoading] = useState(true);

  const checkAuthStatus = async () => {
    try {
      const user = await getCurrentUser();
      setIsSignedIn(!!user);

    } catch (error) {
      setIsSignedIn(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    checkAuthStatus(); // Check auth status when the provider mounts
  }, []);

  useEffect(() => {
    console.log("Setting sessionStorage with isSignedIn:", isSignedIn);
    // Persist the state to sessionStorage whenever it changes
    sessionStorage.setItem("isSignedIn", isSignedIn);
  }, [isSignedIn]);

  const signout = async () => {
    try {
      await signOut();
      setIsSignedIn(false);
    } catch (error) {
      console.error("Sign out error:", error);
    }
  };


  return (
    <AuthContext.Provider value={{ isSignedIn, signin, signout,checkAuthStatus, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
