import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { signIn, signUp } from 'aws-amplify/auth'; // Import Auth from AWS Amplify
import './App.css'; // Use the provided CSS

const RegisterPage = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(''); // State to store error messages

  const handleRegister = async (e) => {
    e.preventDefault();
    setErrorMessage(''); // Clear any previous error messages
    try {
      // Check if user already exists
      await signIn({username:email, password:password});
      setErrorMessage('User already registered. Please sign in instead.');
    } catch (error) {
      if (error.code === 'UserNotFoundException') {
        // If user not found, proceed to sign up
        try {
          await signUp({
            username: email,
            password,
            attributes: {
              email, // Additional attributes can be added here
            },
          });
          window.location.href = '/VerifyAccount'; // Redirect to verify account page
        } catch (signupError) {
          console.error('Error signing up:', signupError);
          setErrorMessage(signupError.message); // Display sign-up error
        }
      } else if (error.code === 'NotAuthorizedException') {
        // If user exists but password is incorrect
        setErrorMessage('User already registered. Please sign in instead.');
      } else {
        // Handle any other errors
        console.error('Error checking user:', error);
        setErrorMessage(error.message);
      }
    }
  };

  return (
    <div className="register">
      <div className="inner">
        <div className="register-left"></div>
        <div className="register-form">
          <h2>Register</h2>
          <p>Create your account. It’s free and only takes a minute.</p>
          {errorMessage && <p className="error-message">{errorMessage}</p>} {/* Display error messages */}
          <form className="form" onSubmit={handleRegister}>
            <div className="form-group">
              <input type="text" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} required />
            </div>
            <div className="form-group">
              <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required />
            </div>
            <div className="form-group">
              <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required />
            </div>
            <div className="form-group login-btn">
              <button type="submit" className="btn">Register</button>
            </div>
            <div className="checkbox">
              <label>
                <input type="checkbox" />
                I agree to the <a href="#" className="terms">Terms and Conditions</a>
              </label>
            </div>
            <div className="links">
              <Link className="link-item" to="/Login">
                Already have an Account? LogIn
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default RegisterPage;
