import React, { useState } from 'react';
import { confirmSignUp } from 'aws-amplify/auth'; // Import Auth from AWS Amplify
import './App.css'; // Use the provided CSS

const VerifyAccount = () => {
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleVerify = async (e) => {
    e.preventDefault();

    if (!email) {
      setError('Email is required.');
      return;
    }

    if (!code) {
      setError('Verification code is required.');
      return;
    }

    try {
      await confirmSignUp({username:email, confirmationCode:code});
      setSuccess('Account successfully verified! You can now log in.');
      setError('');
      setTimeout(() => {
        window.location.href = '/Login'; // Redirect to login page
      }, 2000);
    } catch (err) {
      console.error('Error verifying account:', err);
      setError('Failed to verify account. Please check the code and try again.');
      setSuccess('');
    }
  };

  return (
    <div className="verify">
      <div className="inner">
        <div className="verify-form">
          <h2>Verify Account</h2>
          <p>Please enter the verification code sent to your email.</p>
          {error && <p className="error">{error}</p>}
          {success && <p className="success">{success}</p>}
          <form className="form" onSubmit={handleVerify}>
            <div className="form-group">
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                placeholder="Verification Code"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                required
              />
            </div>
            <div className="form-group login-btn">
              <button type="submit" className="btn">
                Verify
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default VerifyAccount;
