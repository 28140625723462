// Header.js
import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from './AuthContext';
import './App.css';

const MainNav = () => {
  const { isSignedIn, loading } = useAuth();

  if (loading) return null;

  return (
    <header className="header">
      <div className="main-menu">
        <ul className="nav">
        <li className="active"><Link to="/">Home</Link></li>
        {isSignedIn && <li><Link to="/Upload">Upload</Link></li>}
        {isSignedIn && <li><Link to="/Download">Download</Link></li>}
        <li><Link to="/Login">Login/Register</Link></li>
        <li><Link to="/SignOut">Signout</Link></li>
        </ul>
      </div>
    </header>
  );
};

export default MainNav;

