import React, { useState, useEffect } from 'react';
import { getCurrentUser } from 'aws-amplify/auth';
import { uploadFile } from './S3Uploader';
import './App.css';

const Upload = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [message, setMessage] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [uploadMessages, setUploadMessages] = useState([]);
  const [username, setUsername] = useState('');

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const user = await getCurrentUser();
        setUsername(user.username);
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    };

    fetchUser();
  }, []);

  const handleFileChange = (e) => {
    setSelectedFiles(e.target.files);
    setMessage('');
    setUploadMessages([]);
  };

  const handleUpload = async (files) => {
    setIsUploading(true);
    const messages = [];
    for (const file of files) {
      try {
        const data = await uploadFile(file, username);
        console.log('Upload success', data);
        messages.push(`${file.name} uploaded successfully!`);
      } catch (err) {
        console.error('Upload error', err);
        messages.push(`${file.name} failed to upload. Error: ${err.message}`);
      }
    }
    setIsUploading(false);
    setUploadMessages(messages);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedFiles.length > 0) {
      handleUpload(selectedFiles);
      setMessage('Files ready to be uploaded.');
    } else {
      alert('Please select files to upload.');
    }
  };

  return (
    <div className="document-upload">
      <div className="overlay"></div>
        <form className="upload-form" onSubmit={handleSubmit}>
          <h2>Upload Files</h2>
          <div className="form-group">
            <label htmlFor="fileInput">Select files to upload:</label>
            <input
              type="file"
              id="fileInput"
              multiple
              onChange={handleFileChange}
            />
          </div>
          <button type="submit" disabled={isUploading}>
            {isUploading ? 'Uploading...' : 'Upload'}
          </button>
          {message && <p className="success">{message}</p>}
          {selectedFiles.length > 0 && (
            <div className="file-list">
              <h3>Selected Files:</h3>
              <ul>
                {Array.from(selectedFiles).map((file, index) => (
                  <li key={index}>{file.name}</li>
                ))}
              </ul>
            </div>
          )}
          {uploadMessages.length > 0 && (
            <div className="upload-messages">
              {uploadMessages.map((msg, index) => (
                <p key={index} className={msg.includes('successfully') ? 'success' : 'error'}>
                  {msg}
                </p>
              ))}
            </div>
          )}
        </form>
      
    </div>
  );
};

export default Upload;
