import React, { useState, useEffect } from 'react';
import { getCurrentUser } from 'aws-amplify/auth';
import { listProcessedFiles, getFileDownloadUrl } from './S3Uploader';
import './App.css';

const Download = () => {
  const [files, setFiles] = useState([]);
  const [username, setUsername] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const user = await getCurrentUser();
        setUsername(user.username);
        console.log('Current username:', user.username); // Log the username
        const processedFiles = await listProcessedFiles(user.username);
        console.log('Processed files:', processedFiles); // Log the files
        setFiles(processedFiles);
      } catch (error) {
        console.error('Error fetching user or files:', error);
        setError('Error fetching files. Please try again.');
      }
    };

    fetchUser();
  }, []);

  const handleDownload = async (key) => {
    try {
      const url = await getFileDownloadUrl(key);
      window.open(url, '_blank');
    } catch (error) {
      console.error('Error generating download URL:', error);
      setError('Error downloading file. Please try again.');
    }
  };

  return (
    <div className="download">
      <div className="overlay"></div>
      <div className="download-form">
        <h2>Download Processed Files</h2>
        {error && <p className="error">{error}</p>}
        {files.length > 0 ? (
          <div className="file-list">
            <ul>
              {files.map((file, index) => (
                <li key={index}>
                  {file.Key} <button onClick={() => handleDownload(file.Key)}>Download</button>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <p>No processed files available.</p>
        )}
      </div>
    </div>
  );
};

export default Download;
