// ForgotPassword.jsx
import React, { useState } from 'react';
import { resetPassword, confirmResetPassword } from 'aws-amplify/auth';
import { useNavigate } from 'react-router-dom';

const ForgotPassword = () => {
  const [username, setUsername] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [resetStep, setResetStep] = useState('RESET_REQUEST'); // RESET_REQUEST or CONFIRM_PASSWORD
  const [message, setMessage] = useState('');
  const navigate = useNavigate(); 

  // Step 1: Request Password Reset
  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      const output = await resetPassword({ username });
      handleResetPasswordNextSteps(output);
    } catch (error) {
      console.error('Error requesting password reset:', error);
      setMessage('Failed to send reset code. Please check the username.');
    }
  };

  const handleResetPasswordNextSteps = (output) => {
    const { nextStep } = output;
    switch (nextStep.resetPasswordStep) {
      case 'CONFIRM_RESET_PASSWORD_WITH_CODE':
        setResetStep('CONFIRM_PASSWORD');
        const codeDeliveryDetails = nextStep.codeDeliveryDetails;
        setMessage(`Confirmation code was sent via ${codeDeliveryDetails.deliveryMedium}.`);
        break;
      case 'DONE':
        setMessage('Password reset successful. Please log in with your new password.');
        break;
      default:
        setMessage('Unexpected step.');
    }
  };

  // Step 2: Confirm New Password
  const handleConfirmResetPassword = async (e) => {
    e.preventDefault();
    try {
      await confirmResetPassword({ username, confirmationCode, newPassword });
      setMessage('Password reset successful. Redirecting to login...');
      setTimeout(() => {
        navigate('/Login'); // Redirect to login page after success
      }, 2000); // Optional delay of 2 seconds before redirection
    } catch (error) {
      console.error('Error confirming new password:', error);
      setMessage('Failed to reset password. Please try again.');
    }
  };

  return (
    <div className="forgot-password">
      <div className="inner">
        <div className="forgot-password-form">
          <h2>Forgot Password</h2>
          {resetStep === 'RESET_REQUEST' ? (
            <form className="form" onSubmit={handleResetPassword}>
              <div className="form-group">
                <input
                  type="email"
                  placeholder="Enter your email"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
              </div>
              <button type="submit" className="btn">Send Reset Code</button>
            </form>
          ) : (
            <form className="form" onSubmit={handleConfirmResetPassword}>
              <div className="form-group">
                <input
                  type="text"
                  placeholder="Confirmation Code"
                  value={confirmationCode}
                  onChange={(e) => setConfirmationCode(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="password"
                  placeholder="New Password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                />
              </div>
              <button type="submit" className="btn">Confirm New Password</button>
            </form>
          )}
          {message && <p>{message}</p>}
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
